<template>
  <v-layout align-center justify-center row>
    <v-flex xs6>
      <Table
        :object="object"
        :headers="headers"
        :hits="hits"
        :search="search"
        :page="page"
        :perPage="perPage"
        :total="total"
        :selected="selected"
        :selectable="true"
        :loading="loading"
        v-on:pagination="log"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Table from '../../components/crud/Table.vue';

export default {
  name: 'TableTest',
  components: {
    Table,
  },
  data() {
    return {
      headers: [
        {
          text: 'Col1',
          value: 'col1',
        },
        {
          text: 'Col2',
          value: 'col2.val',
        },
      ],
      search: 'test',
      hits: [
        {
          col1: 'test1',
          col2: {
            val: 123,
          },
        },
        {
          col1: 'test2',
          col2: {
            val: 124,
          },
        },
        {
          col1: 'test2',
          col2: {
            val: 124,
          },
        },
      ],
      selected: [
        {
          text: '123',
        },
      ],
      page: 1,
      perPage: 5,
      total: 13,
      object: 'test',
      loading: false,
    };
  },
  mounted() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
